/** @jsx jsx */
import { graphql } from "gatsby";
import { Box, Container, Heading, jsx } from "theme-ui";
import ContentWithImage from "../../components/ContentWithImage";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

const TileRoofs = ({ data }) => {
  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="Tile Roofs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerBottomIconAlt="Icon with blue buildings"
      headerFancyBanner={false}
      headerLinkButtonEnabled={true}
    >
      <Seo title="Tile Roofs" />

      <Box as="article" sx={{ my: [8, null, 11] }}>
        <Container>
          <ContentWithImage
            imageAlt="Men passing roof tile to another man on a later"
            imageURL={
              data.MenPassingRoofShingles.childImageSharp.gatsbyImageData
            }
            titleText="Tile Roof Repair in the Greater Phoenix Area"
          >
            <p>
              Tile is one of the most common roof material types in Arizona.
              Concrete, slate and clay-based tile roofs are durable,
              cost-efficient and aesthetically pleasing in the desert, making
              them a great choice for residential and commercial structures.
              Moreover, tile roofs can have a long lifespan of up to 50 years
              with proper maintenance and repairs.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Repairs and Maintenance
            </Heading>

            <p>
              The timing of roof repairs can vary depending on maintenance
              frequency, the age and design of your home, and the amount and
              type of foliage around your home. We typically see that homes in
              the 17-to-25-year-old range are often due for repairs to cracked,
              slipping or missing tiles.
            </p>

            <p>
              It is important to routinely check for cracks and breaks in roof
              tiles, as excessive damage will allow the sun to break down the
              layer under your tile (underlayment). This barrier is actually
              your roof, and its breakdown results in premature system failure.
              Debris that accumulates on a tile roof will block that roof’s
              natural drainage. This blockage can cause water to travel below
              the tile, saturating the underlayment and leading to water
              intrusion in the home.
            </p>

            <p>
              To ensure the longest life possible for your roof, prevent
              premature underlayment failure with regular clearing of debris and
              repairs to tile and flashings by a qualified roofing contractor.
            </p>

            <p>
              As roofs age, the availability of matching replacement tiles
              decreases. In many cases, existing tile may be reused when
              performing a repair. If tile should require replacement and exact
              tile is no longer available, Legacy Repair will find the closest
              color and profile match and install on the least visible area of
              your roof.
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Tile Roof Underlayment
            </Heading>

            <p>
              The barrier under your tile (underlayment) is your actual roof.
              Standard underlayment of builder-grade felt is organic in design
              and resistant to heat (up to 180 degrees under the tile cover).
              Because the tile does not provide a perfect seal, the underlayment
              expands when it gets wet and shrinks when it dries.{" "}
            </p>

            <p>
              Over time, this exposure to heat and moisture causes the
              underlayment to deteriorate and fail. Early signs of this damage
              are typically found on the soffit or eave, in valleys and near
              penetrations (pipes and vents).
            </p>

            <Heading as="h3" variant="styles.h3" sx={{ mt: 6 }}>
              Tile Options
            </Heading>

            <p>
              Tiles are available in many options for both residential and
              commercial use. The most common are concrete, clay, and
              hand-formed clay tile (sand cast).
            </p>

            <p>
              Legacy Repair technicians have the knowledge and tools to safely
              and properly diagnose the origins of a roof leak and prescribe
              effective repairs. This will provide extended roof life and
              watertight integrity to your existing roof system.
            </p>
          </ContentWithImage>
        </Container>
      </Box>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    HeroImage: file(relativePath: { eq: "tile-roofs-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    BuildingsIcon: file(relativePath: { eq: "buildings.svg" }) {
      id
      publicURL
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    MenPassingRoofShingles: file(
      relativePath: { eq: "men-passing-roof-shingles.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default TileRoofs;
